import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from "@mui/icons-material";
import React, { useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import PropTypes from "prop-types";

import { auth } from "../firebase";

export default function ResetPassword(props) {
  const { oobCode } = props;
  const [disabled, setDisabled] = useState(false);
  const [message, setMessage] = useState(null);
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);

  async function handleResetPassword() {
    setDisabled(true);
    try {
      await verifyPasswordResetCode(auth, oobCode);
      await confirmPasswordReset(auth, oobCode, password);
      setMessage("update password success");
    } catch (error) {
      console.error(error);
      setMessage("something went wrong");
    }
  }

  return (
    <Box alignItems="center" display="flex" flexDirection="column">
      <Typography variant="h5" my={2}>Reset Password</Typography>
      <Tooltip arrow placement="bottom-end" title="Password should be at least 8 characters.">
        <TextField
          autoComplete="new-password"
          error={password.length < 8}
          label="New Password"
          margin="dense"
          onChange={(event) => setPassword(event.target.value)}
          required
          type={visible ? "text" : "password"}
          value={password}
          inputProps={{ minLength: 8 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setVisible(!visible)}>
                  {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Tooltip>
      <Button
        onClick={() => handleResetPassword()}
        disabled={disabled || password.length < 8}
        variant="contained"
        sx={{ my: 2 }}
      >
        Submit
      </Button>
      <Box mt={3}>
        <Typography>{message}</Typography>
      </Box>
    </Box>
  )
}

ResetPassword.propTypes = {
  oobCode: PropTypes.string.isRequired,
};
