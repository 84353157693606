import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";

import Account from "./pages/Account";
import SignUp from "./pages/SignUp";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/sign_up" element={<SignUp />} />
        <Route path="/account" element={<Account />} />
        {/* temporary default page */}
        <Route path="*" element={<Navigate to="/sign_up" />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
